import * as React from "react";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import DocumentTitle from "./components/DocumentTitle";
import { GET_PEOPLE_PROFILES_DATA_QUALITY_REPORT } from "../api/GraphQL/queries";
import { useQuery } from "@apollo/client";
import { PeopleProfilesDataQualityReport } from "../models/dataQuality";
import DataQualityTable from "./components/DataQualityTable";
import PersonSelector from "./components/PersonSelector";
import { useLocation, useNavigate } from "react-router-dom";
import DepartmentSelector from "./components/DepartmentSelector";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1)
  },
  filterContainer: {
    minWidth: "100%",
    display: "flex"
  },
  filterItem: {
    margin: theme.spacing(1)
  }
}));

const DataQualityPage: React.FunctionComponent = () => {
  const { data, loading } = useQuery<{ dataQuality: PeopleProfilesDataQualityReport }>(GET_PEOPLE_PROFILES_DATA_QUALITY_REPORT);

  const profileReports = data?.dataQuality.peopleProfiles || [];

  const classes = useStyles();
  const navigate = useNavigate();

  const handleManagersChange = (value: string[]) => {
    updateQueryString(value, selectedDepartmentIds);
  };

  const handleDepartmentsChange = (value: string[]) => {
    updateQueryString(selectedManagerIds, value);
  };

  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const managersParam = queryParams.get("managers");
  const departmentsParam = queryParams.get("departments");
  const decodedDepartmentsParam = departmentsParam ? decodeURIComponent(departmentsParam) : null;
  const selectedManagerIds = React.useMemo(() => (managersParam ? managersParam.split(",").map((p) => p.trim()) : []), [managersParam]);
  const selectedDepartmentIds = React.useMemo(
    () => (decodedDepartmentsParam ? decodedDepartmentsParam.split(",").map((p) => p.trim()) : []),
    [decodedDepartmentsParam]
  );

  const updateQueryString = React.useCallback(
    (managers: string[], departments: string[]) => {
      const managersQuery = managers.length > 0 ? "managers=" + managers.join(",") : "";
      const departmentsQuery = departments.length > 0 ? "departments=" + encodeURIComponent(departments.join(",")) : "";
      let fullQuery = managersQuery;
      fullQuery = fullQuery === "" ? departmentsQuery : fullQuery + (departmentsQuery === "" ? "" : "&" + departmentsQuery);
      navigate(`/data-quality?${fullQuery}`, {
        replace: true
      });
    },
    [navigate]
  );

  return (
    <>
      <DocumentTitle pageTitle="Data Quality" />
      <Typography variant="h6" gutterBottom>
        Profile Data Quality
      </Typography>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Paper className={classes.filterContainer}>
            <Grid item xs={4} className={classes.filterItem}>
              {<DepartmentSelector value={selectedDepartmentIds} onChange={handleDepartmentsChange} />}
            </Grid>
            <Grid item xs={4} className={classes.filterItem}>
              {<PersonSelector value={selectedManagerIds} onChange={handleManagersChange} size="small" />}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DataQualityTable
            profileReports={profileReports}
            managerIds={selectedManagerIds}
            departmentIds={selectedDepartmentIds}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DataQualityPage;
