import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTelemetryContext } from "../../telemetry/TelemetryContextProvider";

const DocumentTitle = (props: { pageTitle?: string }) => {
  const pageTitle = `${props.pageTitle || ""}${props.pageTitle ? " | " : ""}Lantern People`;
  const { ai } = useTelemetryContext();
  useEffect(() => {
    ai?.trackPageView({
      name: pageTitle
    });
  }, [pageTitle, ai]);
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default DocumentTitle;
