import { memo } from "react";
import { Typography } from "@material-ui/core";
import PersonAvatarList from "./PersonAvatarList";
import { Person } from "../../models/people";

const PersonProfileManagerSection = (props: { manager: Person }) => {
  const { manager } = props;
  return (
    <>
      <Typography variant="h5" component="h5" gutterBottom style={{ fontSize: "1.5rem", fontWeight: "500" }}>
        Manager
      </Typography>
      <PersonAvatarList people={[manager]} />
    </>
  );
};

export default memo(PersonProfileManagerSection);
