import * as React from "react";
import FilteredPeopleList from "./components/FilteredPeopleList";
import { Fade } from "@material-ui/core";
import DocumentTitle from "./components/DocumentTitle";

const DashboardPage: React.FunctionComponent = () => {
  return (
    <>
      <DocumentTitle />
      <Fade in>
        <FilteredPeopleList />
      </Fade>
    </>
  );
};
export default DashboardPage;
