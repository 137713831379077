import { useState, memo, ChangeEvent } from "react";
import {
  Card,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Collapse,
  CardContent,
  Button,
  TextField,
  FormControl,
  Grid,
  FormLabel,
  IconButton,
  Chip,
  Checkbox,
  FormControlLabel,
  Switch,
  Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { ProjectParticipant, BeaconItem } from "../../models/people";
import { Alert, Skeleton } from "@material-ui/lab";
import MarkdownEditor from "./MarkdownEditor";
import { useFormik } from "formik";
import Markdown from "./Markdown";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import GroupedBeaconItemList from "./GroupedBeaconItemList";
import dateFormatter from "./formatting/dateFormatter";

type CardVariant = "default" | "condensed";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultIconWidth = 120;
const condensedIconWidth = 80;

const useStyles = (variant: CardVariant) =>
  makeStyles((theme: Theme) => {
    const iconWidth = variant === "condensed" ? condensedIconWidth : defaultIconWidth;

    return createStyles({
      root: {
        position: "relative",
        background: theme.palette.background.default,
        height: iconWidth,
        padding: "0 !important",
        "&.isExpanded $logo": {
          // boxShadow: "-2px -2px 5px rgba(0,0,0,0.5) inset"
        }
      },
      logo: {
        position: "absolute",
        background: "#fff",
        left: 0,
        top: 0,
        width: iconWidth,
        height: iconWidth,
        boxSizing: "border-box",
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        "& > img": {
          alignSelf: "center",
          width: "95%"
        }
      },
      description: {
        marginLeft: iconWidth,
        overflow: "visible",
        position: "relative",
        zIndex: 1,
        display: "flex",
        padding: theme.spacing(2),
        height: "100%",
        paddingBottom: theme.spacing(1),
        flexDirection: "column",
        "& > *:first-child": {
          flexGrow: 1
        }
      },
      statusActive: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main
      },
      activeChip: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main
      },
      completedChip: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white
      },
      onWatchListChip: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main
      },
      title: {
        fontSize: variant === "condensed" ? 15 : 18,
        lineHeight: "22px !important",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": variant === "condensed" ? 2 : 3,
        "-webkit-box-orient": "vertical"
      },
      logoText: {
        fontSize: 75,
        color: theme.palette.common.black,
        lineHeight: "22px !important",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": variant === "condensed" ? 2 : 3,
        "-webkit-box-align": "center",
        marginBlockStart: 12,
        marginBlockEnd: 12
      },
      small: {
        width: theme.spacing(4),
        height: theme.spacing(4)
      },
      expand: {
        marginLeft: "auto",
        "&:hover": {
          backgroundColor: "inherit",
          color: "inherit"
        }
      },
      actions: {
        display: "flex",
        "& > *:first-child": {
          flexGrow: 1
        }
      },
      contribution: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        background: theme.palette.background.default
      },
      header: {
        fontSize: 18,
        fontWeight: 500
      },
      fieldName: {
        fontSize: 16
      },
      chips: {
        marginTop: theme.spacing(1),
        "& > *": {
          marginRight: theme.spacing(1),
          marginBottom: theme.spacing(1)
        }
      },
      missingInfo: {
        color: theme.palette.error.main,
        float: "right"
      },
      image: {
        width: "100%",
        height: "auto"
      },
      [theme.breakpoints.down(350)]: {
        logo: {
          width: condensedIconWidth
        },
        description: {
          marginLeft: condensedIconWidth
        }
      }
    });
  });

interface Contribution {
  role: string;
  duties: string;
  beaconItemIds: string[];
}

const EditableProjectSummaryCard = (props: {
  projectParticipant?: ProjectParticipant;
  variant?: CardVariant;
  canEdit?: boolean;
  beaconItems?: BeaconItem[];
  onSave?: (contribution: Contribution) => Promise<void>;
}) => {
  const { projectParticipant, variant = "default", canEdit, beaconItems: options = [], onSave } = props;
  const project = projectParticipant?.project;

  const classes = useStyles(variant)();

  const [isExpanded, setExpanded] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const missingInfo = !projectParticipant?.role || !projectParticipant?.duties;

  const formik = useFormik({
    initialValues: {
      isExcludedFromProfile: projectParticipant?.isExcludedFromProfile || false,
      role: projectParticipant?.role || "",
      duties: projectParticipant?.duties || "",
      beaconItemIds: (projectParticipant?.skillsUsed || []).map((s) => s.beaconItem.id)
    },
    onSubmit: async (contribution) => {
      setIsSaving(true);
      try {
        await onSave!(contribution);
      } catch (err) {
        console.error(err);
      }
      setIsSaving(false);
      setIsEditing(false);
    },
    validate: (contribution) => {
      const errors = {} as { [key in keyof Contribution]: string };
      const requiredFields: (keyof Contribution)[] = contribution.isExcludedFromProfile ? [] : ["role", "duties"];

      for (const field of requiredFields) {
        if (!contribution[field]) {
          errors[field] = "Required";
        }
      }
      if (contribution.role && contribution.role.length > 128) {
        errors["role"] = "Too long";
      }
      if (contribution.duties && contribution.duties.length > 1024) {
        errors["duties"] = "Too long";
      }
      return errors;
    }
  });

  const toggleBeaconItem = (beaconItemId: string | null) => {
    if (beaconItemId) {
      const has = formik.values.beaconItemIds.indexOf(beaconItemId) >= 0;
      if (has) {
        formik.setFieldValue(
          "beaconItemIds",
          formik.values.beaconItemIds.filter((v) => v !== beaconItemId)
        );
      } else {
        formik.setFieldValue("beaconItemIds", formik.values.beaconItemIds.concat(beaconItemId));
      }
      setInputValue("");
    }
  };

  const handleExpandClick = () => {
    setExpanded(!isExpanded);
  };

  const formatMinutesToHours = (minutes: number | undefined) => {
    return Math.round((minutes || 0) / 60);
  };

  let projectName = project?.name || "";
  const splitName = projectName.split("-");
  if (splitName.length > 1 && splitName[0].trim().length === 3) {
    projectName = splitName
      .slice(1)
      .map((s) => s.trim())
      .join(" - ");
  }

  return (
    <Card>
      <CardContent className={`${classes.root} ${isExpanded ? "isExpanded" : ""}`}>
        <div className={classes.logo}>
          {!project && <Skeleton variant="rect" width="100%" height="100%" />}
          {project &&
            (project.clients || [])
              .filter((client) => !!client.logoUri)
              .map((client) => {
                return (
                  <img
                    key={client.id}
                    className={classes.image}
                    src={client.logoUri}
                    onError={({ currentTarget }) => {
                      //in case of error, set a default image
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "images/icon-32x32.png";
                      currentTarget.alt = "no logo";
                    }}
                    alt="logo"
                  />
                );
              })}
          {project &&
            (project.clients || [])
              .filter((client) => !client.logoUri)
              .map((client) => {
                return (
                  <p key={client.id} className={classes.logoText}>
                    {client.name[0]}
                  </p>
                );
              })}
        </div>
        <div className={classes.description}>
          <div>
            <div className={classes.actions}>
              <Typography variant="h6" component="h2" className={classes.title} title={project?.name} gutterBottom>
                {projectName || <Skeleton variant="text" />}
                {!!project && (
                  <IconButton
                    component="a"
                    href={`https://projects.devfacto.com/projects/${project.id}`}
                    color="primary"
                    size="small"
                    target="_blank"
                  >
                    <LaunchIcon style={{ fontSize: 16, marginLeft: 4 }} />
                  </IconButton>
                )}
              </Typography>
              {missingInfo && (
                <Tooltip title="Missing project details" placement="top">
                  <ErrorOutlineIcon className={classes.missingInfo} />
                </Tooltip>
              )}
            </div>
            {project ? (
              <Typography variant="inherit" color="textSecondary">
                {projectParticipant?.firstTimeEntryDate ? (
                  <>
                    {dateFormatter.formatCalendarDate(projectParticipant.firstTimeEntryDate, "MMM dd, yyyy")}
                    {" - "}
                  </>
                ) : (
                  "∞"
                )}
                {projectParticipant?.lastTimeEntryDate
                  ? dateFormatter.formatCalendarDate(projectParticipant.lastTimeEntryDate, "MMM dd, yyyy")
                  : projectParticipant?.lastTimeEntryDate
                  ? "∞"
                  : ""}
              </Typography>
            ) : (
              <Skeleton variant="text" width="50%" />
            )}
          </div>
          <div className={classes.actions}>
            <div>
              <Typography color="textSecondary">{projectParticipant?.role}</Typography>
            </div>
            {!!project && (
              <Button
                variant="text"
                size="small"
                onClick={handleExpandClick}
                aria-expanded={isExpanded}
                aria-label="show more"
                className={classes.expand}
                endIcon={<ExpandMoreIcon />}
                style={{ textTransform: "none" }}
              >
                {formatMinutesToHours(projectParticipant?.contributedTimeInMinutes)}h
              </Button>
            )}
          </div>
        </div>
      </CardContent>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.contribution}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.actions}>
                <Typography variant="h6" className={classes.header}>
                  Contribution ({formatMinutesToHours(projectParticipant?.contributedTimeInMinutes)} hours)
                </Typography>
                {canEdit && !isEditing && (
                  <IconButton color="primary" size="small" onClick={() => setIsEditing(true)}>
                    <EditIcon />
                  </IconButton>
                )}
              </div>
            </Grid>
            {canEdit && isEditing ? (
              <Grid item xs={12}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    {!formik.values.isExcludedFromProfile && (
                      <Grid item xs={12}>
                        <FormControl error={!!formik.errors.role}>
                          <FormLabel style={{ fontSize: "14px" }}>Role</FormLabel>
                          <TextField
                            name="role"
                            variant="outlined"
                            value={formik.values.role}
                            fullWidth
                            size="small"
                            onChange={formik.handleChange}
                            error={!!formik.errors.role}
                            helperText={formik.errors.role}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {!formik.values.isExcludedFromProfile && (
                      <Grid item xs={12}>
                        <MarkdownEditor
                          labelText="Duties"
                          value={formik.values.duties}
                          onChange={(value: string) => {
                            formik.setFieldValue("duties", value);
                          }}
                          error={formik.errors.duties}
                        />
                      </Grid>
                    )}
                    {!formik.values.isExcludedFromProfile && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <FormLabel style={{ fontSize: "14px" }}>Tools, Techniques, Technologies</FormLabel>
                          <Autocomplete
                            // loading={loading}
                            value={null}
                            disableCloseOnSelect
                            inputValue={inputValue}
                            onInputChange={(event, value: string) => {
                              setInputValue(value);
                            }}
                            clearOnEscape
                            options={options.map((beaconItem) => beaconItem.id)}
                            getOptionLabel={(value) => {
                              const option = options.find((p) => p.id === value);
                              return option ? option.name : ""; // Use a fallback value if 'option' is undefined
                            }}
                            onChange={(event: ChangeEvent<object>, beaconItemId: string | null) => toggleBeaconItem(beaconItemId)}
                            renderOption={(beaconItemId: string) => {
                              const option = options.find((v) => v.id === beaconItemId);
                              return (
                                <>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={!!formik.values.beaconItemIds.find((v) => v === beaconItemId)}
                                  />
                                  {option?.name}
                                </>
                              );
                            }}
                            renderTags={(value: string[], getTagProps) =>
                              value.map((option: string, index: number) => {
                                const person = options.find((p) => p.id === option);
                                return <Chip key={person?.id} variant="outlined" label={person?.name} {...getTagProps({ index })} />;
                              })
                            }
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" placeholder="Add skills" size="small" fullWidth />
                            )}
                          />
                          <div className={classes.chips}>
                            {formik.values.beaconItemIds.map((id) => {
                              const beaconItem = options.find((i) => i.id === id);
                              return beaconItem ? <Chip key={id} label={beaconItem.name} size="small" /> : null;
                            })}
                          </div>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!formik.values.isExcludedFromProfile}
                            onChange={() => formik.setFieldValue("isExcludedFromProfile", !formik.values.isExcludedFromProfile)}
                            name="isExcludedFromProfile"
                            color="primary"
                          />
                        }
                        label="Show on profile"
                      />
                      {formik.values.isExcludedFromProfile && (
                        <Alert severity="warning">This project is hidden and will not be included in generated resumes.</Alert>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" type="submit" disabled={isSaving || !formik.isValid}>
                        {isSaving ? "Saving..." : "Save"}
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEditing(false);
                          formik.resetForm();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            ) : !projectParticipant?.isExcludedFromProfile ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.fieldName}>
                    Role
                  </Typography>
                  <Typography variant="body2">{projectParticipant?.role || "¯\\(ツ)/¯"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.fieldName}>
                    Duties
                  </Typography>
                  <Markdown>{projectParticipant?.duties || "¯\\\\_(ツ)_/¯"}</Markdown>
                </Grid>
                {!!projectParticipant?.skillsUsed.length && (
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.fieldName}>
                      Skills used
                    </Typography>
                    <GroupedBeaconItemList items={(projectParticipant?.skillsUsed || []).map((s) => s.beaconItem)} />
                  </Grid>
                )}
              </>
            ) : null}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default memo(EditableProjectSummaryCard);
