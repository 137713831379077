import { memo } from "react";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 4,
    width: 24,
    height: 24,
    fontSize: "0.75rem",
    fontWeight: 600,
    background: theme.palette.common.white,
    color: "#000"
  },
  active: {
    background: theme.palette.primary.light
  }
}));

interface PersonWorkingHoursDayOfWeekProps {
  abbreviation: string;
  name: string;
  active: boolean;
}

const PersonWorkingHoursDayOfWeek = (props: PersonWorkingHoursDayOfWeekProps) => {
  const classes = useStyles();
  return (
    <Avatar alt={props.name} className={`${classes.root} ${props.active ? classes.active : ""}`}>
      {props.abbreviation}
    </Avatar>
  );
};

export default memo(PersonWorkingHoursDayOfWeek);
