import { useState, useEffect, memo } from "react";
import { PersonDetail, PersonEducationType } from "../../models/people";
import { makeStyles, Typography, IconButton, List } from "@material-ui/core";
import { useNotifications } from "./NotificationProvider";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import EditablePersonEducation from "./EditablePersonEducation";
import { PersonEducationUpsertInput, UPSERT_EDUCATION_MUTATION, DELETE_EDUCATION_MUTATION } from "../../api/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import dateFormatter from "./formatting/dateFormatter";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    }
  },
  headings: {
    fontWeight: 600,
    fontSize: "1.5rem"
  },
  text: {
    color: theme.palette.type === "dark" ? theme.palette.common.white : theme.palette.common.black
  }
}));

const PersonProfileEducationSection = (props: { canEdit: boolean; person: PersonDetail | undefined }) => {
  const classes = useStyles();

  const { person } = props;
  const { setNotification } = useNotifications();

  const [isAddingNewEducation, setIsAddingNewEducation] = useState(false);

  const [upsertEducation, { error: upsertEducationError }] = useMutation<{ education: PersonEducationUpsertInput }>(
    UPSERT_EDUCATION_MUTATION,
    {
      onCompleted: () => {
        setNotification("Education updated", "success");
      }
    }
  );

  const [deleteEducation, { error: deleteEducationError }] = useMutation<{ id: number }>(DELETE_EDUCATION_MUTATION, {
    onCompleted: () => {
      setNotification("Education deleted", "warning");
    }
  });

  useEffect(() => {
    if (!!upsertEducationError || !!deleteEducationError) {
      setNotification(upsertEducationError?.message || deleteEducationError?.message, "error");
    }
  }, [upsertEducationError, deleteEducationError, setNotification]);

  const onSaveEducation = async (value: PersonEducationUpsertInput) => {
    await upsertEducation({
      variables: {
        education: {
          id: value.id,
          personId: value.personId,
          type: value.type,
          name: value.name,
          institution: value.institution,
          description: value.description,
          linkUri: value.linkUri,
          dateAchieved: value.dateAchieved,
          includeDateAchievedToProfile: value.includeDateAchievedToProfile
        }
      }
    });
    if (!value.id) {
      setIsAddingNewEducation(false);
    }
  };

  const canEdit = props.canEdit && !!person;

  return (
    <>
      <div className={classes.actions}>
        <Typography className={classes.headings} component="h5">
          {person ? "Education & Training" : null}
          {!person && <Skeleton variant="text" width="30%" />}
        </Typography>
        {canEdit && (
          <IconButton color="primary" size="small" onClick={() => setIsAddingNewEducation(true)}>
            <AddIcon />
          </IconButton>
        )}
      </div>
      {canEdit && isAddingNewEducation && (
        <EditablePersonEducation
          education={
            {
              id: null,
              personId: person?.id,
              type: PersonEducationType.Unknown,
              institution: "",
              name: "",
              description: null,
              linkUri: null,
              dateAchieved: dateFormatter.getCurrentDateAsCalendarDate(),
              includeDateAchievedToProfile: false
            } as PersonEducationUpsertInput
          }
          canEdit={canEdit}
          editOnly
          onSave={onSaveEducation}
          onCancel={() => setIsAddingNewEducation(false)}
        />
      )}
      {!!person?.education && !!person?.education.length ? (
        <List dense>
          {person?.education.map((education) => {
            return (
              <EditablePersonEducation
                key={education.id}
                education={education}
                canEdit={canEdit}
                onSave={onSaveEducation}
                onDelete={async () => {
                  if (window.confirm("Are you sure you want to delete this record?")) {
                    await deleteEducation({ variables: { id: education.id } });
                  }
                }}
              />
            );
          })}
        </List>
      ) : (
        <Typography variant="body2" component="div" className={classes.text}>
          {person ? "Knows some things. Probably" : null}
          {!person && (
            <div>
              <Skeleton variant="text" width="75%" />
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="75%" />
              <Skeleton variant="text" width="50%" />
            </div>
          )}
        </Typography>
      )}
    </>
  );
};

export default memo(PersonProfileEducationSection);
