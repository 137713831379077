import { memo } from "react";
import { Grid } from "@material-ui/core";
import { PersonAvatar } from "@devfacto/react-components";
import { Person } from "../../models/people";

interface PersonManagerProps {
  people: Person[] | null;
}

const PersonAvatarList = (props: PersonManagerProps) => {
  const { people } = props;
  return (
    <Grid container spacing={2}>
      {people?.map((person) => {
        return (
          <Grid key={person.id} item xs="auto">
            <PersonAvatar
              variant="fullName"
              link={`/people/${person.id}`}
              givenName={person.givenName}
              surname={person.surname}
              photoUri={person.photos.length ? person.photos[0].uri : undefined}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default memo(PersonAvatarList);
