import { Avatar, Chip, TextField, createStyles, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useNotifications } from "./NotificationProvider";
import { Autocomplete } from "@material-ui/lab";
import { GET_MANAGERS } from "../../api/GraphQL/queries";
import { useQuery } from "@apollo/client";
import { ManagerQuickSearch, ManagerQuickSearchResult } from "../../models/people";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(3)
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.type === "dark" ? theme.palette.common.white : "#646464"
      },
      "& .MuiInput-underline::after": {
        borderBottomColor: theme.palette.type === "dark" ? theme.palette.common.white : "#646464"
      }
    },
    textField: {
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.type === "dark" ? theme.palette.common.white : "#646464"
      }
    },
    hintText: {
      color: theme.palette.type === "dark" ? theme.palette.common.white : "#646464"
    }
  })
);

const PersonSelector = (props: {
  value: string[];
  onChange: (value: string[]) => unknown;
  size?: "small" | "medium";
  variant?: "standard" | "outlined" | "filled";
}) => {
  const { loading, error, data } = useQuery<ManagerQuickSearch>(GET_MANAGERS);
  const { setNotification } = useNotifications();

  React.useEffect(() => {
    if (error?.message) {
      setNotification(error?.message, "error");
    }
  }, [error, setNotification]);

  const managers = [...(data?.managers ?? [])] as ManagerQuickSearchResult[];
  managers.sort((a, b) => (a.fullName < b.fullName ? -1 : 1));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        loading={loading}
        id="tags-filled"
        value={props.value}
        options={managers.map((manager) => manager.id)}
        getOptionLabel={(value) => {
          return managers.find((m) => m.id === value)?.fullName || "";
        }}
        onChange={(event, newValue: unknown[]) => {
          const stringValues = newValue as string[];
          props.onChange(stringValues);
        }}
        size={props.size ?? "medium"}
        renderTags={(value: unknown[], getTagProps) =>
          (value as string[]).map((option: string, index: number) => {
            const manager = managers.find((p: { id: string }) => p.id === option);
            return (
              <Chip
                key={null}
                variant="outlined"
                label={manager?.fullName}
                avatar={<Avatar alt={manager?.fullName} src={manager?.photos.length ? manager.photos[0].uri : "/images/no_photo.png"} />}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              className: classes.hintText
            }}
            variant={props.variant || "outlined"}
            size={props.size ?? "small"}
            label="Manager"
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default PersonSelector;
