import { useState, memo, ChangeEvent } from "react";
import { BeaconItem, PersonSkill } from "../../models/people";
import { Chip, TextField, makeStyles, IconButton, Grid, Checkbox, Typography, Link, Button } from "@material-ui/core";
import HeartIcon from "@material-ui/icons/Favorite";
import HeartOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import GroupedPersonSkillList from "./GroupedPersonSkillList";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  chips: {
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));

const EditablePersonSkills = (props: {
  isEditing: boolean;
  value: PersonSkill[];
  options: BeaconItem[];
  onSave: (value: PersonSkill[]) => Promise<void>;
  onCancel: () => void;
}) => {
  const classes = useStyles();
  const { options, value, isEditing, onSave, onCancel } = props;
  const [inputValue, setInputValue] = useState<string>("");
  const [workingValue, setWorkingValue] = useState<PersonSkill[]>(value);
  const [isSaving, setIsSaving] = useState(false);

  const sortedWorkingValue = [...workingValue].sort((a, b) => {
    return a.beaconItem.name < b.beaconItem.name ? -1 : a.beaconItem.name > b.beaconItem.name ? 1 : 0;
  });

  const toggleBeaconItem = (beaconItemId: string | null) => {
    if (beaconItemId) {
      if (workingValue.find((v) => v.beaconItem.id === beaconItemId)) {
        setWorkingValue(workingValue.filter((v) => v.beaconItem.id !== beaconItemId));
      } else {
        const beaconItem = options.find((i) => i.id === beaconItemId);
        if (beaconItem) {
          setWorkingValue(workingValue.concat({ id: 0, isLoved: false, beaconItem }));
        }
      }
      setInputValue("");
    }
  };

  const toggleHeart = (beaconItemId: string) => {
    setWorkingValue(
      workingValue.map((v) => {
        if (v.beaconItem.id === beaconItemId) {
          return { ...v, isLoved: !v.isLoved };
        }
        return v;
      })
    );
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    try {
      await onSave(workingValue);
    } catch (err) {
      console.error(err);
    }
    setIsSaving(false);
  };

  return (
    <Grid container spacing={2}>
      {isEditing ? (
        <>
          <Grid item xs={12}>
            <Autocomplete
              // loading={loading}
              id="tags-filled"
              value={null}
              disableCloseOnSelect
              inputValue={inputValue}
              onInputChange={(event, newValue: string) => {
                setInputValue(newValue);
              }}
              clearOnEscape
              options={options.map((beaconItem) => beaconItem.id)}
              getOptionLabel={(optionValue) => {
                const option = options.find((p) => p.id === optionValue);
                return option ? option.name : "";
              }}
              onChange={(event: ChangeEvent<object>, beaconItemId: string | null) => toggleBeaconItem(beaconItemId)}
              renderOption={(beaconItemId: string) => {
                const option = options.find((v) => v.id === beaconItemId);
                return (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={!!workingValue.find((v) => v.beaconItem.id === beaconItemId)}
                    />
                    {option?.name}
                  </>
                );
              }}
              renderTags={(currentValue: string[], getTagProps) =>
                currentValue.map((option: string, index: number) => {
                  const person = options.find((p) => p.id === option);
                  return <Chip key={person?.id} variant="outlined" label={person?.name} {...getTagProps({ index })} />;
                })
              }
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Add skills" size="small" fullWidth />}
            />
            <Link target="_blank" href="https://beacon.devfacto.com?view=items" style={{ fontSize: 14 }}>
              Available Tools, Techniques, and Technologies are managed in Beacon
            </Link>
            <div className={classes.chips}>
              {sortedWorkingValue.length > 0 &&
                sortedWorkingValue.map((item) => {
                  return (
                    <Chip
                      icon={
                        item.isLoved ? (
                          <IconButton onClick={() => toggleHeart(item.beaconItem.id)} size="small">
                            <HeartIcon fontSize="small" style={{ color: "#f44336" }} />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => toggleHeart(item.beaconItem.id)} size="small">
                            <HeartOutlinedIcon
                              fontSize="small"
                              //   style={{ color: "#f44336" }}
                            />
                          </IconButton>
                        )
                      }
                      key={item.id}
                      label={item.beaconItem.name}
                      onDelete={() => toggleBeaconItem(item.beaconItem.id)}
                    />
                  );
                })}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleSaveClick} disabled={isSaving}>
              {isSaving ? "Saving..." : "Save"}
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} className={classes.chips}>
          <GroupedPersonSkillList skills={value} />
          {value.length === 0 && (
            <Typography variant="body2" component="p">
              Keeping secrets
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default memo(EditablePersonSkills);
