import * as React from "react";
import { makeStyles, Typography, IconButton, Tabs, Tab } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import EditableParagraph from "./EditableParagraph";
import { useMutation } from "@apollo/client";
import { PersonProfileInput, PersonDetail } from "../../models/people";
import { UPDATE_PROFILE_MUTATION } from "../../api/GraphQL/mutations";
import { useNotifications } from "./NotificationProvider";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    }
  },
  tabs: {
    marginBottom: theme.spacing(2),
    borderRadius: 4
  },
  tabIndicator: {
    backgroundColor: theme.palette.warning.main
  },
  headings: {
    fontWeight: 600,
    fontSize: "1.5rem"
  }
}));

type BioSection = "bio" | "devfactoWorkDescription";

const PersonProfileBioSection = (props: { canEdit: boolean; person: PersonDetail | undefined }) => {
  const classes = useStyles();

  const { person } = props;
  const { setNotification } = useNotifications();

  const [isEditingBio, setIsEditingBio] = React.useState(false);

  const [selectedBioSection, setSelectedBioSection] = React.useState<BioSection>("bio");

  const handleTabSelect = (event: React.ChangeEvent<object>, newValue: BioSection) => {
    setSelectedBioSection(newValue);
  };

  const [updateProfile, { error: updateProfileError }] = useMutation<{ profile: PersonProfileInput }>(UPDATE_PROFILE_MUTATION, {
    onCompleted: () => {
      setIsEditingBio(false);
      setNotification("Profile updated", "success");
    }
  });

  React.useEffect(() => {
    if (updateProfileError) {
      setNotification(updateProfileError?.message, "error");
    }
  }, [updateProfileError, setNotification]);

  const canEdit = props.canEdit && !!person;

  return (
    <>
      <div className={classes.actions}>
        <Typography className={classes.headings} component="h5">
          {person ? "Bio" : null}
          {!person && <Skeleton variant="text" width="20%" />}
        </Typography>
        {canEdit && !isEditingBio && (
          <IconButton color="primary" size="small" onClick={() => setIsEditingBio(true)}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      {!!person && (
        <Tabs
          TabIndicatorProps={{ className: classes.tabIndicator }}
          value={selectedBioSection}
          onChange={handleTabSelect}
          className={classes.tabs}
        >
          <Tab label={"Professional Summary"} value="bio" style={{ textTransform: "none" }} />
          <Tab label={"Lantern"} value="devfactoWorkDescription" style={{ textTransform: "none" }} />
        </Tabs>
      )}
      {selectedBioSection === "bio" && (
        <>
          {person ? (
            <EditableParagraph
              value={person?.bio || "Such a great person"}
              maxLength={2048}
              isEditing={canEdit && isEditingBio}
              onSave={async (value: string) => {
                await updateProfile({
                  variables: {
                    profile: {
                      id: person.id!,
                      bio: value,
                      devFactoWorkDescription: person.devFactoWorkDescription
                    }
                  }
                });
              }}
              onCancel={() => setIsEditingBio(false)}
            />
          ) : (
            <Typography variant="body2" component="p">
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="40%" />
            </Typography>
          )}
        </>
      )}
      {selectedBioSection === "devfactoWorkDescription" && (
        <>
          {person ? (
            <EditableParagraph
              value={person?.devFactoWorkDescription || "Literally the best"}
              maxLength={2048}
              isEditing={canEdit && isEditingBio}
              onSave={async (value: string) => {
                await updateProfile({
                  variables: {
                    profile: {
                      id: person.id!,
                      bio: person.bio,
                      devFactoWorkDescription: value
                    }
                  }
                });
              }}
              onCancel={() => setIsEditingBio(false)}
            />
          ) : (
            <Typography variant="body2" component="p">
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="40%" />
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(PersonProfileBioSection);
