import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Paper, makeStyles, Grid, FormControlLabel, Switch, Typography } from "@material-ui/core";
import PersonInfo from "./components/PersonInfo";
import { PersonDetail, GraphQlConnection, BeaconItem } from "../models/people";
import { useUserContext } from "../auth/UserContextProvider";
import { GET_MORE_PARTICIPATIONS, GET_PERSON, GET_BEACON_ITEMS, ProjectParticipantExclusionFilter } from "../api/GraphQL/queries";
import PersonProfileBioSection from "./components/PersonProfileBioSection";
import PersonProfileSkillsSection from "./components/PersonProfileSkillsSection";
import PersonProfileEducationSection from "./components/PersonProfileEducationSection";
import PersonProfileManagerSection from "./components/PersonProfileManagerSection";
import PersonProfileDirectReportsSection from "./components/PersonProfileDirectReportsSection";
import PersonProfileProjectsSection from "./components/PersonProfileProjectsSection";
import { useTelemetryContext } from "../telemetry/TelemetryContextProvider";
import DocumentTitle from "./components/DocumentTitle";
import PersonProfilePriorExperienceSection from "./components/PersonProfilePriorExperienceSection";
import { Skeleton } from "@material-ui/lab";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "auto !important",
    paddingBottom: theme.spacing(8)
  },
  pageActions: {
    display: "flex",
    justifyContent: "flex-end",
    background: "rgba(0,0,0,0.05)",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(2),
    height: 38
  },
  details: {
    padding: theme.spacing(2)
  },
  lastUpdated: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium as number,
    fontSize: "1rem",
    "& > *:last-child": {
      display: "inline-block"
    }
  }
}));

const PersonPage: React.FunctionComponent = () => {
  const classes = useStyles();

  const { id } = useParams<{ id: string }>();
  const { user, isProfileAdmin } = useUserContext();

  const [isFetchingMoreProjects, setIsFetchingMoreProjects] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const { ai } = useTelemetryContext();

  const [projectExclusionFilter, setProjectExclusionFilter] = React.useState<ProjectParticipantExclusionFilter>(
    ProjectParticipantExclusionFilter.IncludedOnly
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  React.useEffect(() => {
    if (isEditMode) {
      setIsEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { error, data, fetchMore } = useQuery<{ person: PersonDetail }>(GET_PERSON, {
    variables: {
      id,
      participationsFirst: 5,
      participationsAfter: null,
      exclusionFilter: projectExclusionFilter
    }
  });

  const handleLoadMoreParticipations = async () => {
    setIsFetchingMoreProjects(true);
    await fetchMore({
      query: GET_MORE_PARTICIPATIONS,
      variables: {
        id,
        participationsFirst: 5,
        participationsAfter: data?.person.projectParticipations?.pageInfo.endCursor,
        exclusionFilter: projectExclusionFilter
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult!.person.projectParticipations!.edges;
        const merged = newEdges?.length
          ? {
              person: {
                ...previousResult.person,
                projectParticipations: {
                  __typename: previousResult.person.projectParticipations!.__typename,
                  edges: [...previousResult.person.projectParticipations!.edges, ...newEdges],
                  pageInfo: fetchMoreResult!.person.projectParticipations!.pageInfo,
                  totalCount: fetchMoreResult!.person.projectParticipations!.totalCount
                }
              }
            }
          : previousResult;
        setIsFetchingMoreProjects(false);
        return merged;
      }
    });
  };

  const handleToggleEditMode = () => {
    if (!isEditMode) {
      ai?.trackEvent({ name: "ENABLED_EDIT_PROFILE" });
    }
    setIsEditMode(!isEditMode);
  };

  const { data: beaconItemsData } = useQuery<{
    beaconItems: GraphQlConnection<BeaconItem>;
  }>(GET_BEACON_ITEMS, {
    variables: {
      first: 1000
    }
  });

  const person = data?.person as PersonDetail;
  const canEdit = !!person && (user?.id === person?.id || isProfileAdmin);
  const beaconItems = beaconItemsData?.beaconItems.edges.map((e) => e.node) || [];

  if (error) return <p>Error :(</p>;

  return (
    <>
      <DocumentTitle pageTitle={person?.fullName} />
      <Paper className={classes.root} key={person?.id}>
        <Grid container>
          <Grid item xs={12}>
            {id && <PersonInfo person={person} canEdit={canEdit} personId={id} />}
          </Grid>
          <Grid item xs={12} className={classes.pageActions} container alignItems="center">
            <Typography className={classes.lastUpdated} component="h2">
              Last Updated: {person?.lastUpdatedDateTime ? format(new Date(person.lastUpdatedDateTime), "MMM dd, yyyy") : ""}
              {!person && <Skeleton variant="text" width={75} />}
            </Typography>
            {canEdit && (
              <FormControlLabel
                control={<Switch color="primary" checked={isEditMode} onChange={handleToggleEditMode} name="checkedB" />}
                label="Edit:"
                labelPlacement="start"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.details}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <PersonProfileBioSection person={person} canEdit={isEditMode} />
                    </Grid>
                    <Grid item xs={12}>
                      <PersonProfileSkillsSection person={person} canEdit={isEditMode} beaconItems={beaconItems} />
                    </Grid>
                    <Grid item xs={12}>
                      <PersonProfileEducationSection person={person} canEdit={isEditMode} />
                    </Grid>
                    <Grid item xs={12}>
                      <PersonProfilePriorExperienceSection person={person} canEdit={isEditMode} beaconItems={beaconItems} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={6}>
                    {!!person?.manager && (
                      <Grid item xs={12}>
                        <PersonProfileManagerSection manager={person.manager} />
                      </Grid>
                    )}
                    {!!person?.directReports.length && (
                      <Grid item xs={12}>
                        <PersonProfileDirectReportsSection directReports={person.directReports} />
                      </Grid>
                    )}
                    {!!person?.projectParticipations && (
                      <Grid item xs={12}>
                        <PersonProfileProjectsSection
                          canEdit={isEditMode}
                          person={person}
                          beaconItems={beaconItems}
                          fetchMoreProjects={handleLoadMoreParticipations}
                          isFetchingProjects={isFetchingMoreProjects}
                          exclusionFilter={projectExclusionFilter}
                          onExclusionFilterChange={(value) => setProjectExclusionFilter(value)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PersonPage;
