import * as React from "react";
import { Paper, makeStyles, TableRow, TableContainer, TableHead, TableCell, TableBody, Table } from "@material-ui/core";
import { PersonProfilesDataQualityReport } from "../../models/dataQuality";
import DataQualityTableRow from "./DataQualityTableRow";

const useStyles = makeStyles((theme) => ({
  root: { width: "100%", overflow: "hidden" },
  tableContainer: { minHeight: 600, height: "calc(100vh - 200px)" },
  tableHeader: {
    "& > tr > th": { backgroundColor: theme.palette.background.paper }
  },
  centered: {
    textAlign: "center"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  }
}));

const DataQualityTable: React.FunctionComponent<{
  profileReports: PersonProfilesDataQualityReport[];
  managerIds: string[];
  departmentIds: string[];
  loading: boolean;
}> = (props) => {
  const classes = useStyles();
  const { profileReports, managerIds, departmentIds, loading } = props;

  const filterdProfileReports = React.useMemo(() => {
    return profileReports.filter((report) => {
      const matchManager = managerIds.length === 0 || managerIds.includes(report.person.managerId ?? "");
      const matchDepartment = departmentIds.length === 0 || departmentIds.includes(report.person.department ?? "");
      return matchManager && matchDepartment;
    });
  }, [profileReports, managerIds, departmentIds]);

  const sortedProfileReports = [...filterdProfileReports].sort((a, b) => {
    if (a.overallScore > b.overallScore) {
      return -1;
    } else if (a.overallScore < b.overallScore) {
      return 1;
    }
    return 0;
  });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell className={classes.right}>Score</TableCell>
              <TableCell className={classes.centered}>Bio</TableCell>
              <TableCell className={classes.centered}>Role Description</TableCell>
              <TableCell className={classes.centered}>Skills</TableCell>
              <TableCell className={classes.centered}>Work Experience</TableCell>
              <TableCell className={classes.centered}>Education</TableCell>
              <TableCell className={classes.left}>Projects</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              [1, 2, 3, 4, 5, 6, 7].map((i) => {
                return <DataQualityTableRow key={i} />;
              })}
            {!loading && sortedProfileReports.map((report) => <DataQualityTableRow key={report.person.id} report={report} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DataQualityTable;
