import * as React from "react";
import { useCallback } from "react";

export type NotificationSeverity = "success" | "info" | "warning" | "error" | undefined;

interface NotificationContextValue {
  message?: string;
  severity?: "success" | "info" | "warning" | "error";
  setNotification: (value: string | undefined, severity: NotificationSeverity) => unknown;
  clearNotification: () => unknown;
}

export const NotificationContext = React.createContext<NotificationContextValue>({
  setNotification: () => {},
  clearNotification: () => {}
});

export const useNotifications = () => React.useContext(NotificationContext);

const NotificationContextProvider = (props: { children: React.ReactNode }) => {
  const [message, setMessage] = React.useState<string>();
  const [severity, setSeverity] = React.useState<NotificationSeverity>();

  const updateMessage = useCallback((value: string | undefined, severity: NotificationSeverity) => {
    setMessage(value);
    setSeverity(severity);
  }, []);

  const clearNotification = useCallback(() => {
    setMessage(undefined);
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        message,
        severity,
        setNotification: updateMessage,
        clearNotification
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
