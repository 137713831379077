import { memo } from "react";
import { Typography } from "@material-ui/core";
import PersonAvatarList from "./PersonAvatarList";
import { Person } from "../../models/people";

const PersonProfileManagerSection = (props: { directReports: Person[] }) => {
  const { directReports } = props;
  const activeDirectReports = directReports.filter((person) => person.isEnabled === true);
  return (
    <>
      <Typography variant="h5" component="h5" gutterBottom style={{ fontSize: "1.5rem", fontWeight: "500" }}>
        Supervising
      </Typography>
      <PersonAvatarList people={activeDirectReports} />
    </>
  );
};

export default memo(PersonProfileManagerSection);
