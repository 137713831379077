import { LinearProgress, makeStyles } from "@material-ui/core";
import * as React from "react";

const useProgressStyles = makeStyles((theme) => ({
  success: {
    width: 100,
    color: theme.palette.success.main,
    backgroundColor: theme.palette.grey[100],
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.success.main
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: theme.palette.success.main
    }
  },
  warning: {
    width: 100,
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.grey[100],
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.warning.main
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: theme.palette.warning.main
    }
  },
  error: {
    width: 100,
    color: theme.palette.error.main,
    backgroundColor: theme.palette.grey[100],
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.error.main
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: theme.palette.error.main
    }
  },
  scoreCell: {
    textAlign: "center"
  }
}));

const DataQualityScoreProgressBar: React.FunctionComponent<{ score: number }> = (props) => {
  const classes = useProgressStyles();
  let className = classes.error;
  if (props.score === 100) {
    className = classes.success;
  } else if (props.score > 0) {
    className = classes.warning;
  }
  return <LinearProgress variant="determinate" className={className} value={props.score || 5} />;
};

export default DataQualityScoreProgressBar;
