import * as React from "react";
import DocumentTitle from "./components/DocumentTitle";
import { LoadingView } from "@devfacto/react-components";

const AuthCompletePage: React.FunctionComponent = () => {
  return (
    <>
      <DocumentTitle pageTitle="Authenticating" />
      <LoadingView text="Completing auth..." />
    </>
  );
};

export default AuthCompletePage;
