import * as React from "react";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import { ProjectParticipantExclusionFilter } from "../../api/GraphQL/queries";
import { PersonProfilesDataQualityReport } from "../../models/dataQuality";
import { PersonDetail } from "../../models/people";
import DataQualityScoreIcon from "./DataQualityScoreIcon";

const GET_PERSON_PROJECTS = gql`
  query GetPerson(
    $id: String!
    $participationsFirst: Int
    $participationsAfter: String
    $exclusionFilter: ProjectParticipantExclusionFilter
  ) {
    person(id: $id) {
      id
      projectParticipations(first: $participationsFirst, after: $participationsAfter, exclusionFilter: $exclusionFilter) {
        edges {
          node {
            id
            isExcludedFromProfile
            contributedTimeInMinutes
            firstTimeEntryDate
            lastTimeEntryDate
            project {
              id
              name
              clients {
                id
                name
                logoUri
              }
              startDate
              dueDate
              isArchived
              status
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
`;

const DataQualityTableRowDetails: React.FunctionComponent<{ personDataQualityReport: PersonProfilesDataQualityReport }> = (props) => {
  const { personDataQualityReport: report } = props;
  const { data, loading } = useQuery<{ person: Partial<PersonDetail> }>(GET_PERSON_PROJECTS, {
    variables: {
      id: report.person.id,
      participationsFirst: 1000,
      exclusionFilter: ProjectParticipantExclusionFilter.IncludedOnly
    }
  });

  const reportProjects = report.fields.find((f) => f.fieldName.toLowerCase() === "projects");

  const projects = (data?.person.projectParticipations?.edges || [])
    .map((e) => e.node)
    .map((p) => {
      const projectReport = reportProjects?.children
        .filter((rp) => rp.score < 100)
        .find((rp) => Number(rp.entityId) === Number(p.project.id));

      return {
        projectParticipant: p,
        report: projectReport
      };
    })
    .filter((p) => !!p.report);

  return (
    <>
      <Typography>{loading ? "Fetching projects..." : "Projects missing contribution details:"}</Typography>
      {!!projects.length && (
        <List dense>
          {projects.map((p) => {
            const projectReport = p.report;
            const project = p.projectParticipant.project;

            return (
              <ListItem key={project.id}>
                <ListItemIcon>
                  <DataQualityScoreIcon score={projectReport!.score} violations={projectReport!.violations} />
                </ListItemIcon>
                <ListItemText primary={project.name} secondary={(project.clients || []).map((c) => c.name).join(", ")} />
              </ListItem>
            );
          })}
        </List>
      )}
      {!projects.length && !loading && (
        <Typography variant="body2" gutterBottom>
          None! All projects have been filled out. 🎉👍
        </Typography>
      )}
    </>
  );
};

export default DataQualityTableRowDetails;
