import { useRef } from "react";
import axios from "axios";
import { ApplicationContext } from "../models/appContext";

const useAppContextApi = () => {
  const api = useRef({
    api: {
      getApplicationContext: () => axios.get<ApplicationContext>(`/api/appContext`, {})
    }
  });
  return api.current;
};

export default useAppContextApi;
