import { useState, useEffect, memo } from "react";
import { BeaconItem, PersonDetail } from "../../models/people";
import { makeStyles, Typography, IconButton, List } from "@material-ui/core";
import { useNotifications } from "./NotificationProvider";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import {
  PersonPriorExperienceUpsertInput,
  UPSERT_PRIOR_EXPERIENCE_MUTATION,
  DELETE_PRIOR_EXPERIENCE_MUTATION
} from "../../api/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import EditablePersonPriorExperience from "./EditablePersonPriorExperience";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    }
  },
  headings: {
    fontWeight: 600,
    fontSize: "1.5rem"
  }
}));

const PersonProfilePriorExperienceSection = (props: { canEdit: boolean; person: PersonDetail | undefined; beaconItems: BeaconItem[] }) => {
  const classes = useStyles();

  const { person, beaconItems } = props;
  const { setNotification } = useNotifications();

  const [isAddingNewPriorExperience, setIsAddingNewPriorExperience] = useState(false);

  const [upsertPriorExperience, { error: upsertPriorExperienceError }] = useMutation<{ priorExperience: PersonPriorExperienceUpsertInput }>(
    UPSERT_PRIOR_EXPERIENCE_MUTATION,
    {
      onCompleted: () => {
        setNotification("Experience updated", "success");
      }
    }
  );

  const [deletePriorExperience, { error: deletePriorExperienceError }] = useMutation<{ id: number }>(DELETE_PRIOR_EXPERIENCE_MUTATION, {
    onCompleted: () => {
      setNotification("Experience deleted", "warning");
    }
  });

  useEffect(() => {
    if (!!upsertPriorExperienceError || !!deletePriorExperienceError) {
      setNotification(upsertPriorExperienceError?.message || deletePriorExperienceError?.message, "error");
    }
  }, [upsertPriorExperienceError, deletePriorExperienceError, setNotification]);

  const canEdit = props.canEdit && !!person;

  const onSavePriorExperience = async (value: PersonPriorExperienceUpsertInput) => {
    await upsertPriorExperience({
      variables: {
        priorExperience: {
          id: value.id,
          personId: value.personId,
          companyName: value.companyName,
          roleName: value.roleName,
          roleDescription: value.roleDescription,
          startDate: value.startDate,
          endDate: value.endDate,
          beaconItemIds: value.beaconItemIds
        }
      }
    });
    if (!value.id) {
      setIsAddingNewPriorExperience(false);
    }
  };

  return (
    <>
      <div className={classes.actions}>
        <Typography className={classes.headings} component="h5">
          {person ? "Prior Experience" : null}
          {!person && <Skeleton variant="text" width="30%" />}
        </Typography>
        {canEdit && (
          <IconButton color="primary" size="small" onClick={() => setIsAddingNewPriorExperience(true)}>
            <AddIcon />
          </IconButton>
        )}
      </div>
      {!!person && canEdit && isAddingNewPriorExperience && (
        <EditablePersonPriorExperience
          person={person}
          priorExperience={null}
          canEdit={canEdit}
          beaconItems={beaconItems}
          editOnly
          onSave={onSavePriorExperience}
          onCancel={() => setIsAddingNewPriorExperience(false)}
        />
      )}
      {person?.priorExperience?.length ? (
        <List dense>
          {person?.priorExperience.map((priorExperience) => {
            return (
              <EditablePersonPriorExperience
                key={priorExperience.id}
                person={person}
                priorExperience={priorExperience}
                canEdit={canEdit}
                beaconItems={beaconItems}
                onSave={onSavePriorExperience}
                onDelete={async () => {
                  if (window.confirm("Are you sure you want to delete this record?")) {
                    await deletePriorExperience({ variables: { id: priorExperience.id } });
                  }
                }}
              />
            );
          })}
        </List>
      ) : (
        <Typography variant="body2" component="div">
          {!!person && !canEdit ? "Classified" : null}
          {!person && (
            <div>
              <Skeleton variant="text" width="75%" />
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="75%" />
              <Skeleton variant="text" width="50%" />
            </div>
          )}
        </Typography>
      )}
    </>
  );
};

export default memo(PersonProfilePriorExperienceSection);
