import { memo } from "react";
import { Typography, Grid, Button, makeStyles, IconButton } from "@material-ui/core";
import EditableProjectSummaryCard from "./EditableProjectSummaryCard";
import { PersonDetail, BeaconItem } from "../../models/people";
import { useMutation } from "@apollo/client";
import { ProjectParticipantInput, UPDATE_PROJECT_PARTICIPANT } from "../../api/GraphQL/mutations";
import { useNotifications } from "./NotificationProvider";
import { ProjectParticipantExclusionFilter } from "../../api/GraphQL/queries";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    }
  },
  seeMoreButton: {
    color: theme.palette.type === "dark" ? theme.palette.common.white : theme.palette.common.black
  }
}));

const PersonProfileProjectsSection = (props: {
  canEdit: boolean;
  person: PersonDetail | undefined;
  isFetchingProjects: boolean;
  fetchMoreProjects: () => Promise<void>;
  beaconItems: BeaconItem[];
  exclusionFilter: ProjectParticipantExclusionFilter;
  onExclusionFilterChange: (exclusionFilter: ProjectParticipantExclusionFilter) => void;
}) => {
  const classes = useStyles();
  const { canEdit, person, isFetchingProjects, fetchMoreProjects, beaconItems, exclusionFilter } = props;
  const { setNotification } = useNotifications();

  const projects = person?.projectParticipations?.edges.map((e) => e.node) || [];

  const [updateProjectParticipation] = useMutation<{ projectParticipant: ProjectParticipantInput }>(UPDATE_PROJECT_PARTICIPANT, {
    onCompleted: () => {
      setNotification("Contribution updated", "success");
    }
  });

  const inverseFilter =
    exclusionFilter === ProjectParticipantExclusionFilter.IncludedOnly
      ? ProjectParticipantExclusionFilter.ExcludedOnly
      : ProjectParticipantExclusionFilter.IncludedOnly;

  return (
    <>
      <div className={classes.actions}>
        <Typography variant="h5" component="h3" gutterBottom style={{ fontSize: "1.5rem", fontWeight: "500" }}>
          Recent Projects
        </Typography>
        <IconButton onClick={() => props.onExclusionFilterChange(inverseFilter)}>
          {exclusionFilter === ProjectParticipantExclusionFilter.IncludedOnly ? (
            <VisibilityIcon fontSize="small" />
          ) : (
            <VisibilityOffIcon fontSize="small" />
          )}
        </IconButton>
      </div>
      <Grid container spacing={2}>
        {exclusionFilter === ProjectParticipantExclusionFilter.ExcludedOnly && (
          <Grid item xs={12}>
            <Alert severity="info">The following projects are hidden and will not be included in generated resumes.</Alert>
          </Grid>
        )}
        {projects.map((projectParticipant, index) => {
          return (
            <Grid key={index} item xs={12}>
              <EditableProjectSummaryCard
                projectParticipant={projectParticipant}
                canEdit={canEdit}
                onSave={async (contribution) => {
                  await updateProjectParticipation({
                    variables: {
                      projectParticipant: {
                        projectId: projectParticipant.project.id,
                        personId: person?.id,
                        ...contribution
                      }
                    }
                  });
                }}
                beaconItems={beaconItems}
              />
            </Grid>
          );
        })}
        {isFetchingProjects &&
          [0, 1, 2].map((i) => {
            return (
              <Grid key={i} item xs={12}>
                <EditableProjectSummaryCard />
              </Grid>
            );
          })}
        {person?.projectParticipations?.pageInfo.hasNextPage && (
          <Grid item xs={12}>
            <Button variant="text" onClick={fetchMoreProjects} disabled={isFetchingProjects}>
              {isFetchingProjects ? "Fetching..." : "See More"}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default memo(PersonProfileProjectsSection);
