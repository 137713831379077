import { memo } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { PersonWorkingHours } from "../../models/people";
import PersonWorkingHoursDayOfWeek from "./PersonWorkingHoursDayOfWeek";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 8
  },
  workingHours: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  week: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    marginBottom: theme.spacing(0.5)
  },
  weekLabel: {
    color: theme.palette.common.white,
    marginTop: 2,
    marginRight: 4,
    fontWeight: 600,
    fontSize: "0.875rem"
  },
  workingTimeZone: {
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: "0.875rem"
  }
}));

const PersonWorkingHoursView = (props: { workingHours: PersonWorkingHours }) => {
  const classes = useStyles();

  const formatTime = (time: string | null): string => {
    if (!time) {
      return "";
    }
    const split = time.split(":");
    return `${split[0]}:${split[1]}`;
  };
  const { workingHours } = props;
  if (!workingHours) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.workingHours}>
        <Typography className={classes.weekLabel}>Working hours:</Typography>
        <div className={classes.week}>
          <PersonWorkingHoursDayOfWeek
            name="Sunday"
            abbreviation="S"
            active={workingHours.daysOfWeek.some((d) => d.toLowerCase() === "sunday")}
          />
          <PersonWorkingHoursDayOfWeek
            name="Monday"
            abbreviation="M"
            active={workingHours.daysOfWeek.some((d) => d.toLowerCase() === "monday")}
          />
          <PersonWorkingHoursDayOfWeek
            name="Tuesday"
            abbreviation="T"
            active={workingHours.daysOfWeek.some((d) => d.toLowerCase() === "tuesday")}
          />
          <PersonWorkingHoursDayOfWeek
            name="Wednesday"
            abbreviation="W"
            active={workingHours.daysOfWeek.some((d) => d.toLowerCase() === "wednesday")}
          />
          <PersonWorkingHoursDayOfWeek
            name="Thursday"
            abbreviation="R"
            active={workingHours.daysOfWeek.some((d) => d.toLowerCase() === "thursday")}
          />
          <PersonWorkingHoursDayOfWeek
            name="Friday"
            abbreviation="F"
            active={workingHours.daysOfWeek.some((d) => d.toLowerCase() === "friday")}
          />
          <PersonWorkingHoursDayOfWeek
            name="Saturday"
            abbreviation="S"
            active={workingHours.daysOfWeek.some((d) => d.toLowerCase() === "saturday")}
          />
        </div>
      </div>
      <Typography className={classes.workingTimeZone}>
        {formatTime(workingHours?.startTime)} - {formatTime(workingHours?.endTime)} {workingHours?.timeZone}
      </Typography>
    </div>
  );
};

export default memo(PersonWorkingHoursView);
