import * as React from "react";
import useAppContextApi from "../../../api/appContextApi";
import { ApplicationContext } from "../../../models/appContext";
import { AuthContextProvider } from "../../../auth/AuthProvider";
import { LoadingView } from "@devfacto/react-components";

interface AppContextValue {
  context: ApplicationContext | null;
}
export const AppContext = React.createContext<AppContextValue>({ context: null });
export const useAppContext = () => React.useContext(AppContext);

const AppContextProvider = (props: { children: React.ReactNode }) => {
  const [appContext, setAppContext] = React.useState<ApplicationContext>();
  const { api } = useAppContextApi();

  React.useEffect(() => {
    async function loadApplicationContext() {
      const response = await api.getApplicationContext();
      setAppContext(response.data);
    }
    loadApplicationContext();
  }, [api]);

  return appContext ? (
    <AuthContextProvider settings={appContext.settings.authSettings}>
      <AppContext.Provider value={{ context: appContext }}>{props.children}</AppContext.Provider>
    </AuthContextProvider>
  ) : (
    <LoadingView text="Initializing..." />
  );
};

export default AppContextProvider;
