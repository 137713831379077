import { useState, useEffect, memo, ChangeEvent } from "react";
import {
  Button,
  makeStyles,
  Grid,
  TextField,
  Paper,
  IconButton,
  Fade,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  FormControl,
  FormLabel,
  Checkbox,
  Chip
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import EditIcon from "@material-ui/icons/Edit";
import { useFormik } from "formik";
import { BeaconItem, PersonDetail, PersonPriorExperience } from "../../models/people";
import { PersonPriorExperienceUpsertInput } from "../../api/GraphQL/mutations";
import Markdown from "./Markdown";
import MarkdownEditor from "./MarkdownEditor";
import GroupedBeaconItemList from "./GroupedBeaconItemList";
import dateFormatter from "./formatting/dateFormatter";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    }
  },
  editPaper: {
    padding: theme.spacing(2)
  },
  editButton: {
    opacity: 0
  },
  deleteButton: {
    color: theme.palette.error.main,
    "&.MuiButton-root:hover": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.black
    }
  },
  roleName: {
    marginLeft: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.type === "dark" ? theme.palette.common.white : theme.palette.common.black
  },
  chips: {
    marginTop: theme.spacing(1),
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  text: {
    color: theme.palette.type === "dark" ? theme.palette.common.white : theme.palette.common.black
  }
}));

const EditablePersonPriorExperience = (props: {
  person: PersonDetail;
  priorExperience: PersonPriorExperience | null;
  editOnly?: boolean;
  canEdit?: boolean;
  beaconItems?: BeaconItem[];
  onSave: (value: PersonPriorExperienceUpsertInput) => Promise<void>;
  onDelete?: () => Promise<void>;
  onCancel?: () => void;
}) => {
  const classes = useStyles();
  const { person, priorExperience, onSave, editOnly, canEdit, beaconItems: options = [], onCancel, onDelete } = props;
  const [isEditing, setIsEditing] = useState(editOnly);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [inputValue, setInputValue] = useState<string>("");

  const defaultPerson = {
    id: null,
    personId: person.id,
    companyName: "",
    roleName: "",
    roleDescription: "",
    startDate: dateFormatter.getCurrentDateAsCalendarDate(),
    endDate: dateFormatter.getCurrentDateAsCalendarDate(),
    beaconItemIds: []
  } as PersonPriorExperienceUpsertInput;

  const formik = useFormik({
    initialValues:
      priorExperience === null
        ? defaultPerson
        : ({
            ...priorExperience,
            startDate: priorExperience.startDate,
            endDate: priorExperience.endDate,
            beaconItemIds: priorExperience.skillsUsed.map((s) => s.beaconItem.id)
          } as PersonPriorExperienceUpsertInput),
    onSubmit: async (value) => {
      setIsSaving(true);
      try {
        await onSave(value);
        setIsEditing(false);
      } catch (err) {
        console.log(err);
      }
      setIsSaving(false);
    },
    validate: (value) => {
      const errors = {} as { [key in keyof PersonPriorExperienceUpsertInput]: string };
      const requiredFields: (keyof PersonPriorExperienceUpsertInput)[] = [
        "companyName",
        "roleName",
        "roleDescription",
        "startDate",
        "endDate"
      ];
      for (const field of requiredFields) {
        if (!value[field]) {
          errors[field] = "Required";
        }
      }
      if (value.companyName && value.companyName.length > 255) {
        errors.companyName = "Too long";
      }
      if (value.roleName && value.roleName.length > 255) {
        errors.roleName = "Too long";
      }
      if (value.roleDescription && value.roleDescription.length > 2000) {
        errors.roleDescription = "Too long";
      }
      return errors;
    }
  });

  const handleDeleteClick = async () => {
    setIsDeleting(true);
    try {
      await onDelete!();
    } catch (err) {
      console.log(err);
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    formik.setValues(
      priorExperience === null
        ? defaultPerson
        : {
            ...priorExperience,
            startDate: priorExperience.startDate,
            endDate: priorExperience.endDate,
            beaconItemIds: priorExperience.skillsUsed.map((s) => s.beaconItem.id)
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorExperience]);

  const cancelEdit = () => {
    setIsEditing(false);
    formik.setValues(
      priorExperience === null
        ? defaultPerson
        : {
            ...priorExperience,
            startDate: priorExperience.startDate,
            endDate: priorExperience.endDate,
            beaconItemIds: priorExperience.skillsUsed.map((s) => s.beaconItem.id)
          }
    );
    !!onCancel && onCancel();
  };

  const toggleBeaconItem = (beaconItemId: string | null) => {
    if (beaconItemId) {
      const has = formik.values.beaconItemIds.indexOf(beaconItemId) >= 0;
      if (has) {
        formik.setFieldValue(
          "beaconItemIds",
          formik.values.beaconItemIds.filter((v) => v !== beaconItemId)
        );
      } else {
        formik.setFieldValue("beaconItemIds", formik.values.beaconItemIds.concat(beaconItemId));
      }
      setInputValue("");
    }
  };

  return canEdit && isEditing ? (
    <Fade in>
      <Paper className={classes.editPaper}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="companyName"
                label="Company name"
                value={formik.values.companyName}
                error={!!formik.errors.companyName}
                onChange={formik.handleChange}
                helperText={formik.errors.companyName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="roleName"
                label="Role"
                value={formik.values.roleName}
                error={!!formik.errors.roleName}
                onChange={formik.handleChange}
                helperText={formik.errors.roleName}
              />
            </Grid>
            <Grid item xs={12}>
              <MarkdownEditor
                labelText="Role description"
                value={formik.values.roleDescription}
                error={formik.errors.roleDescription}
                onChange={(value) => formik.setFieldValue("roleDescription", value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="startDate"
                    name="startDate"
                    label="Start date"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="endDate"
                    name="endDate"
                    label="End date"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel style={{ fontSize: "14px" }}>Tools, Techniques, Technologies</FormLabel>
                <Autocomplete
                  // loading={loading}
                  value={null}
                  disableCloseOnSelect
                  inputValue={inputValue}
                  onInputChange={(event, value: string) => {
                    setInputValue(value);
                  }}
                  clearOnEscape
                  options={options.map((beaconItem) => beaconItem.id)}
                  getOptionLabel={(value) => {
                    const option = options.find((p) => p.id === value);
                    return option ? option.name : ""; // Use a fallback value if 'option' is undefined
                  }}
                  onChange={(event: ChangeEvent<object>, beaconItemId: string | null) => toggleBeaconItem(beaconItemId)}
                  renderOption={(beaconItemId: string) => {
                    const option = options.find((v) => v.id === beaconItemId);
                    return (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={!!formik.values.beaconItemIds.find((v) => v === beaconItemId)}
                        />
                        {option?.name}
                      </>
                    );
                  }}
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const match = options.find((p) => p.id === option);
                      return <Chip key={person?.id} variant="outlined" label={match?.name} {...getTagProps({ index })} />;
                    })
                  }
                  renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Add skills" size="small" fullWidth />}
                />
                <div className={classes.chips}>
                  {formik.values.beaconItemIds.map((id) => {
                    const beaconItem = options.find((i) => i.id === id);
                    return beaconItem ? <Chip key={id} label={beaconItem.name} size="small" /> : null;
                  })}
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.actions}>
                <div>
                  <Button type="submit" disabled={!formik.isValid || isSaving || isDeleting}>
                    {isSaving ? "Saving..." : "Save"}
                  </Button>
                  <Button onClick={cancelEdit}>Cancel</Button>
                </div>
                {onDelete && (
                  <div>
                    <Button className={classes.deleteButton} onClick={handleDeleteClick} disabled={isSaving || isDeleting}>
                      {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fade>
  ) : priorExperience !== null ? (
    <ListItem>
      <ListItemText
        primary={
          <Typography>
            {priorExperience.companyName} <span className={classes.roleName}>{priorExperience.roleName}</span>
          </Typography>
        }
        secondary={
          <>
            <Typography variant="subtitle2" className={classes.text}>
              {dateFormatter.formatCalendarDate(priorExperience.startDate, "MMM yyyy")} -{" "}
              {dateFormatter.formatCalendarDate(priorExperience.endDate, "MMM yyyy")}
            </Typography>
            <Typography variant="body2" gutterBottom className={classes.text}>
              <Markdown>{priorExperience.roleDescription || ""}</Markdown>
            </Typography>
            {priorExperience.skillsUsed?.length > 0 && (
              <div>
                <Typography variant="body2" className={classes.text}>
                  Skills used
                </Typography>
                <GroupedBeaconItemList items={priorExperience.skillsUsed.map((s) => s.beaconItem)} />
              </div>
            )}
          </>
        }
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton onClick={() => setIsEditing(true)} size="small" color="primary">
            <EditIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  ) : null;
};

export default memo(EditablePersonPriorExperience);
