import { addMilliseconds, format } from "date-fns";

const dateFormatter = {
  // JavaScript will assume date in yyyy-MM-dd is UTC as a new Date object is created.
  // We need to counter that by removing the offset.
  formatCalendarDate: (dateString: string, displayFormat: string) => {
    if (dateString) {
      const date = new Date(dateString);
      const dateWithoudOffset = addMilliseconds(date, date.getTimezoneOffset() * 60000);
      return format(dateWithoudOffset, displayFormat);
    }
    return null;
  },
  getCurrentDateAsCalendarDate: () => {
    return format(new Date(), "yyyy-MM-dd");
  }
};

export default dateFormatter;
