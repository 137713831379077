import * as ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";

import AppContextProvider from "./pages/components/layout/AppContextProvider";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;

const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </BrowserRouter>,
  rootElement
);

unregister();
