import { Route, Routes } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import PersonPage from "./pages/PersonPage";
import AuthCompletePage from "./pages/AuthCompletePage";
import AuthenticatedView from "./pages/components/layout/AuthenticatedView";
import RedirectToMyProfilePage from "./pages/RedirectToMyProfilePage";
import DataQualityPage from "./pages/DataQualityPage";

const App = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedView>
            <DashboardPage />
          </AuthenticatedView>
        }
      />
      <Route
        path="/me"
        element={
          <AuthenticatedView>
            <RedirectToMyProfilePage />
          </AuthenticatedView>
        }
      />
      <Route
        path="/people/:id"
        element={
          <AuthenticatedView>
            <PersonPage />
          </AuthenticatedView>
        }
      />
      <Route
        path="/data-quality"
        element={
          <AuthenticatedView>
            <DataQualityPage />
          </AuthenticatedView>
        }
      />
      <Route path="/authComplete" element={<AuthCompletePage />} />
    </Routes>
  );
};

export default App;
