import { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import "./styles/markdown-editor.scss";
import Markdown from "./Markdown";
import MarkdownEditor from "./MarkdownEditor";

const EditableParagraph = (props: {
  value?: string;
  maxLength?: number;
  isEditing: boolean;
  onSave: (value: string) => Promise<void>;
  onCancel: () => void;
}) => {
  const { value = ":(", maxLength, isEditing, onSave, onCancel } = props;
  const [workingValue, setWorkingValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveClick = async () => {
    setIsSaving(true);
    try {
      await onSave(workingValue);
    } catch (err) {
      console.error(err);
    }
    setIsSaving(false);
  };

  const isTooLong = !!maxLength && !!workingValue && workingValue.length > maxLength;

  return isEditing ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MarkdownEditor
          value={workingValue}
          error={isTooLong ? "Too long" : undefined}
          onChange={(newValue) => setWorkingValue(newValue)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleSaveClick} disabled={isSaving || isTooLong}>
          {isSaving ? "Saving..." : "Save"}
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Grid>
    </Grid>
  ) : (
    <Markdown>{props.value || ""}</Markdown>
  );
};

export default EditableParagraph;
