import * as React from "react";
import { Navigate } from "react-router";
import { useUserContext } from "../auth/UserContextProvider";

const RedirectToMyProfilePage: React.FunctionComponent = () => {
  const { user } = useUserContext();
  return user ? <Navigate to={`/people/${user?.id}`} /> : null;
};

export default RedirectToMyProfilePage;
