import { useState, useEffect, memo } from "react";
import { useMutation } from "@apollo/client";
import { Typography, IconButton, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import EditablePersonSkills from "./EditablePersonSkills";
import { PersonDetail, BeaconItem } from "../../models/people";
import { PersonSkillsInput, UPDATE_PERSON_SKILLS } from "../../api/GraphQL/mutations";
import { useNotifications } from "./NotificationProvider";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    }
  },
  headings: {
    fontWeight: 600,
    fontSize: "1.5rem"
  }
}));

const PersonProfileSkillsSection = (props: { canEdit: boolean; person: PersonDetail | undefined; beaconItems: BeaconItem[] }) => {
  const classes = useStyles();
  const { setNotification } = useNotifications();
  const { person, beaconItems } = props;
  const [isEditingSkills, setIsEditingSkills] = useState(false);

  const [updateSkills, { error: upsertSkillError }] = useMutation<{ skills: PersonSkillsInput }>(UPDATE_PERSON_SKILLS, {
    onCompleted: () => {
      setIsEditingSkills(false);
      setNotification("Skills updated", "success");
    }
  });

  useEffect(() => {
    if (upsertSkillError) {
      setNotification(upsertSkillError?.message, "error");
    }
  }, [upsertSkillError, setNotification]);

  const canEdit = props.canEdit && !!person;

  return (
    <>
      <div className={classes.actions}>
        <Typography className={classes.headings} component="h5">
          {person ? "Skills" : null}
          {!person && <Skeleton variant="text" width="20%" />}
        </Typography>
        {canEdit && !isEditingSkills && (
          <IconButton color="primary" size="small" onClick={() => setIsEditingSkills(true)}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      {person ? (
        <EditablePersonSkills
          isEditing={canEdit && isEditingSkills}
          value={person?.skills || []}
          options={beaconItems}
          onSave={async (updatedSkills) => {
            await updateSkills({
              variables: {
                skills: {
                  personId: person?.id,
                  skills: updatedSkills.map((s) => ({ beaconItemId: s.beaconItem.id, isLoved: s.isLoved }))
                }
              }
            });
          }}
          onCancel={() => setIsEditingSkills(false)}
        />
      ) : (
        <Typography variant="body2" component="p">
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="50%" />
        </Typography>
      )}
    </>
  );
};

export default memo(PersonProfileSkillsSection);
