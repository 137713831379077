import { useState, useMemo } from "react";
import { SimpleMdeReact, SimpleMdeToCodemirrorEvents } from "react-simplemde-editor";
import SimpleMDE from "easymde";
import { FormControl, FormHelperText, FormLabel } from "@material-ui/core";

const MarkdownEditor = (props: { labelText?: string; value: string; error?: string; onChange: (newValue: string) => unknown }) => {
  // public state: MarkdownEditorState = { isFocused: false };
  const [isFocused, setIsFocused] = useState(false);
  const { labelText, value, error } = props;

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  const SimpleMDEOptions = useMemo(() => {
    return {
      spellChecker: false,
      hideIcons: ["image", "side-by-side", "fullscreen", "guide"],
      status: false,
      minHeight: "100px"
    } as SimpleMDE.Options;
  }, []);

  return (
    <FormControl fullWidth className={`markdown-editor ${isFocused ? "focused" : ""} ${error ? "error" : ""}`} error={!!error}>
      {labelText ? <FormLabel style={{ fontSize: "14px" }}>{labelText}</FormLabel> : null}
      <SimpleMdeReact
        value={value}
        onChange={props.onChange}
        events={{ focus: onFocus, blur: onBlur } as SimpleMdeToCodemirrorEvents}
        options={SimpleMDEOptions}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default MarkdownEditor;
