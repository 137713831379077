import { Card, Grid, CardContent } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const LoadingCards = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <Skeleton variant="rect" height={390} width="100%" />
            <CardContent>
              <Skeleton variant="text" />
              <Skeleton variant="text" width="50%" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <Skeleton variant="rect" height={390} width="100%" />
            <CardContent>
              <Skeleton variant="text" />
              <Skeleton variant="text" width="50%" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <Skeleton variant="rect" height={390} width="100%" />
            <CardContent>
              <Skeleton variant="text" />
              <Skeleton variant="text" width="50%" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoadingCards;
