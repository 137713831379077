import { Typography, makeStyles, Grid } from "@material-ui/core";
import * as React from "react";
import Icon from "@mui/material/Icon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "175px",
    height: "40px",
    alignItems: "center",
    marginBottom: "8px",
    backgroundColor: theme.palette.error.main,
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      width: "100px"
    }
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  image: {
    alignContent: "center",
    fontSize: "large",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.5)
    }
  },
  title: {
    justifyContent: "center"
  },
  titleText: {
    color: theme.palette.common.white,
    fontSize: "1.25rem",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      fontWeight: 500
    }
  }
}));

const InactiveScoreComponent: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={3} className={classes.image}>
        <Icon>
          <ErrorOutlineIcon />
        </Icon>
      </Grid>
      <Grid item xs="auto">
        <div className={classes.title}>
          <Typography className={classes.titleText}>Inactive</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default InactiveScoreComponent;
