import * as React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { DataQualityRule } from "../../models/dataQuality";

const useStyles = makeStyles((theme) => ({
  root: {},
  success: {
    fontSize: 24,
    color: theme.palette.success.light
  },
  warning: {
    fontSize: 24,
    color: theme.palette.warning.light
  },
  error: {
    fontSize: 24,
    color: theme.palette.error.light
  },
  loading: {
    fontSize: 24,
    color: theme.palette.grey[200]
  },
  scoreCell: {
    textAlign: "center"
  }
}));

const awesomeEmojis = ["👍", "🌈", "🦄", "🚀", "👌", "🥰", "🤗", "🙌", "❤️", "🍩"];

const DataQualityScoreIcon: React.FunctionComponent<{ score?: number; violations?: DataQualityRule[] }> = (props) => {
  const classes = useStyles();
  const [awesomeEmoji] = React.useState(awesomeEmojis[Math.floor(Math.random() * awesomeEmojis.length)]);
  let className = classes.error;
  if (props.score === undefined) {
    className = classes.loading;
  } else if (props.score === 100) {
    className = classes.success;
  } else if (props.score > 0) {
    className = classes.warning;
  }
  const violations = props.violations || [];

  return (
    <Tooltip
      title={!props.score || props.score < 100 ? violations.map((v) => v.description).join(", ") : awesomeEmoji}
      placement="bottom-start"
    >
      {props.score === undefined || props.score === 100 ? (
        <CheckCircleOutlineIcon className={className} />
      ) : (
        <ErrorOutlineIcon className={className} />
      )}
    </Tooltip>
  );
};

export default DataQualityScoreIcon;
