import * as React from "react";
import { makeStyles, Container, ThemeProvider, CssBaseline, useMediaQuery } from "@material-ui/core";
import { useAuth } from "../../../auth/AuthProvider";
import NavMenu from "./NavMenu";
import UserContextProvider from "../../../auth/UserContextProvider";
import AuthenticatedApolloProvider from "../../../api/GraphQL/AuthenticatedApolloProvider";
import { useAppContext } from "./AppContextProvider";
import { GetLanternAppTheme, LoadingView } from "@devfacto/react-components";
import NotificationContextProvider from "../NotificationProvider";
import Notifications from "../Notifications";
import TelemetryContextProvider from "../../../telemetry/TelemetryContextProvider";
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: 80
  },
  authenticating: {
    padding: theme.spacing(4),
    width: 200,
    display: "flex"
  }
}));

const AuthenticatedView = (props: { children: React.ReactNode }) => {
  const classes = useStyles();
  const { isLoading, isAuthenticated, login, getAccessToken } = useAuth();
  const { context } = useAppContext();
  const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !!login) {
      window.sessionStorage.setItem("redirectUri", window.location.pathname);
      login();
    }
  }, [isLoading, isAuthenticated, login, getAccessToken]);

  return isAuthenticated ? (
    <ThemeProvider theme={GetLanternAppTheme({ authenticated: true, type: theme })}>
      <CssBaseline />
      <NotificationContextProvider>
        <AuthenticatedApolloProvider uri={`${context?.settings.apiUrl}graphql`}>
          <UserContextProvider>
            <TelemetryContextProvider>
              <NavMenu />
              <Notifications />
              <Container maxWidth="lg">
                <main className={classes.main}>
                  <div className="main-content">{props.children}</div>
                </main>
              </Container>
            </TelemetryContextProvider>
          </UserContextProvider>
        </AuthenticatedApolloProvider>
      </NotificationContextProvider>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={GetLanternAppTheme({ authenticated: false })}>
      <LoadingView text="Authenticating..." />
    </ThemeProvider>
  );
};

export default AuthenticatedView;
