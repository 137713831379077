import { useQuery } from "@apollo/client";
import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PeopleSearchResponse } from "../../models/people";
import { GET_LOCATIONS_AND_DEPARTMENTS } from "../../api/GraphQL/queries";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DepartmentSelector = (props: { value: string[]; onChange: (value: string[]) => unknown; size?: "small" | "medium" }) => {
  const { loading, data } = useQuery<{ peopleSearch: PeopleSearchResponse }>(GET_LOCATIONS_AND_DEPARTMENTS);
  const departments = data?.peopleSearch.facets.filter((f) => f.fieldName === "department")[0]?.values || [];

  return (
    <div>
      <Autocomplete
        multiple
        loading={loading}
        size={props.size ?? "medium"}
        renderOption={(option: string, { selected }: { selected: boolean }) => (
          <li>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
        onChange={(event, newValue: unknown[]) => {
          const stringValues = newValue as string[];
          props.onChange(stringValues);
        }}
        renderInput={(params) => <TextField {...params} variant="outlined" size={props.size ?? "small"} label="Department" fullWidth />}
        options={departments ? departments.map((d) => d.value) : []}
      />
    </div>
  );
};

export default DepartmentSelector;
