import { gql } from "@apollo/client";
import { PersonEducationType } from "../../models/people";

export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($profile: PersonProfileInput!) {
    personProfile(person: $profile) {
      id
      bio
      devFactoWorkDescription
    }
  }
`;

export interface PersonEducationUpsertInput {
  id: number | null;
  personId: string;
  type: PersonEducationType;
  institution: string;
  name: string;
  description: string | null;
  linkUri: string | null;
  dateAchieved: string;
  includeDateAchievedToProfile: boolean;
}

export const UPSERT_EDUCATION_MUTATION = gql`
  mutation updateProfile($education: PersonEducationUpsertInput!) {
    personEducationUpsert(education: $education) {
      id
      education {
        id
        personId
        type
        institution
        name
        description
        linkUri
        dateAchieved
        includeDateAchievedToProfile
      }
    }
  }
`;

export const DELETE_EDUCATION_MUTATION = gql`
  mutation updateProfile($id: Int!) {
    personEducationDelete(id: $id) {
      id
      education {
        id
        personId
        type
        institution
        name
        description
        linkUri
        dateAchieved
        includeDateAchievedToProfile
      }
    }
  }
`;

export interface PersonPriorExperienceUpsertInput {
  id: number | null;
  personId: string;
  companyName: string;
  roleName: string;
  roleDescription: string;
  startDate: string;
  endDate: string;
  beaconItemIds: string[];
}

export const UPSERT_PRIOR_EXPERIENCE_MUTATION = gql`
  mutation updateProfile($priorExperience: PersonPriorExperienceUpsertInput!) {
    personPriorExperienceUpsert(priorExperience: $priorExperience) {
      id
      priorExperience {
        id
        personId
        companyName
        roleName
        roleDescription
        startDate
        endDate
        skillsUsed {
          beaconItem {
            id
            name
            category
            previewDescription
          }
        }
      }
    }
  }
`;

export const DELETE_PRIOR_EXPERIENCE_MUTATION = gql`
  mutation deletePriorExperience($id: Int!) {
    personPriorExperienceDelete(id: $id) {
      id
      priorExperience {
        id
        personId
        companyName
        roleName
        roleDescription
        startDate
        endDate
        skillsUsed {
          beaconItem {
            id
            name
            category
            previewDescription
          }
        }
      }
    }
  }
`;

export const UPDATE_PERSON_SKILLS = gql`
  mutation updateSkills($skills: PersonSkillsInput!) {
    personSkillsUpdate(skills: $skills) {
      id
      skills {
        id
        beaconItem {
          id
          name
          category
          previewDescription
        }
        isLoved
      }
    }
  }
`;

export interface PersonSkillsInput {
  personId: string;
  skills: PersonSkillsItemInput[];
}

export interface PersonSkillsItemInput {
  beaconItemId: string;
  isLoved: boolean;
}

export const UPDATE_PROJECT_PARTICIPANT = gql`
  mutation updateProjectParticipant($projectParticipant: ProjectParticipantInput!) {
    projectParticipant(projectParticipant: $projectParticipant) {
      id
      isExcludedFromProfile
      contributedTimeInMinutes
      firstTimeEntryDate
      lastTimeEntryDate
      role
      duties
      skillsUsed {
        beaconItem {
          id
          name
          category
          previewDescription
        }
      }
      project {
        id
        name
        clients {
          id
          name
          logoUri
        }
        startDate
        dueDate
        isArchived
        status
      }
    }
  }
`;

export interface ProjectParticipantInput {
  projectId: number;
  personId: string;
  isExcludedFromProfile: boolean;
  role: string;
  duties: string;
  beaconItemIds: string[];
}

export const GENERATE_RESUME = gql`
  mutation getResume($id: String!) {
    personResume(personId: $id) {
      id
      personId
      uri
    }
  }
`;
