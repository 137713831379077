import { Grid } from "@material-ui/core";
import { PeopleSearchResult } from "../../models/people";
import PeopleSearchResultCard from "./PeopleSearchResultCard";
import LoadingCards from "./LoadingCards";

export interface PeopleSearchResultsListProps {
  peopleSearchResults: PeopleSearchResult[];
  loading: boolean;
}

const PeopleSearchResultList = (props: PeopleSearchResultsListProps) => {
  const { peopleSearchResults: people, loading } = props;
  if (loading) {
    return <LoadingCards />;
  } else {
    return (
      <div>
        <Grid container spacing={3}>
          {people.map((person) => {
            return (
              <Grid key={person.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
                <PeopleSearchResultCard searchResult={person} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
};

export default PeopleSearchResultList;
