import * as React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { useAppContext } from "../pages/components/layout/AppContextProvider";
import { createBrowserHistory } from "history";
import { useUserContext } from "../auth/UserContextProvider";

const browserHistory = createBrowserHistory();

interface TelemetryContextValue {
  ai: ApplicationInsights | undefined;
}

export const TelemetryContext = React.createContext<TelemetryContextValue>({ ai: undefined });
export const useTelemetryContext = () => React.useContext(TelemetryContext);

const TelemetryContextProvider = (props: { children: React.ReactNode }) => {
  const { context } = useAppContext();
  const { user } = useUserContext();

  const reactPlugin = React.useRef(new ReactPlugin()).current;
  const appInsights = React.useRef(
    new ApplicationInsights({
      config: {
        instrumentationKey: context?.settings.appInsightsKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        extensions: [reactPlugin as any],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      }
    })
  ).current;

  React.useEffect(() => {
    appInsights.loadAppInsights();
  }, [appInsights]);

  React.useEffect(() => {
    if (user?.person) {
      appInsights.setAuthenticatedUserContext(user?.id, user?.person.emailAddress, true);
    }
  }, [user, appInsights]);

  return (
    <TelemetryContext.Provider
      value={{
        ai: appInsights
      }}
    >
      {props.children}
    </TelemetryContext.Provider>
  );
};

export default TelemetryContextProvider;
