export interface Person {
  id: string;
  givenName: string;
  surname: string;
  fullName: string;
  jobTitle: string;
  bio: string | null;
  devFactoWorkDescription: string | null;
  emailAddress: string;
  phoneNumber: string;
  officeLocation: string;
  city: string;
  photos: PersonPhoto[];
  manager: Person | null;
  directReports: Person[];
  workingHours: PersonWorkingHours | null;
  isEmployee: boolean;
  isEnabled: boolean;
  isContractor: boolean;
  department: string | null;
  lastUpdatedDateTime: string | null;
  managerId: string | null;
}

export interface PersonDetail extends Person {
  skills?: PersonSkill[];
  education?: PersonEducation[];
  priorExperience?: PersonPriorExperience[];
  projectParticipations?: GraphQlConnection<ProjectParticipant>;
}

export interface PersonSkill {
  id: number;
  beaconItem: BeaconItem;
  isLoved: boolean;
}

export interface PeopleSearchResponse {
  facets: PeopleSearchResultFacet[];
  results: PeopleSearchResult[];
  totalResultsCount: number;
}
export interface PeopleSearchResult {
  id: string;
  person: Person;
  highlights: PeopleSearchResultHighlight[];
}

export interface PeopleSearchResultHighlight {
  matchingFieldName: string;
  matchingTextFragments: string[];
}
export interface PeopleSearchResultFacet {
  fieldName: string;
  values: PeopleSearchResultFacetValue[];
}
export interface PeopleSearchResultFacetValue {
  value: string;
  count: number;
}
export interface PersonResume {
  id: string;
  personId: string;
  uri: string;
}

export interface BeaconItem {
  id: string;
  name: string;
  category?: BeaconItemCategory;
  tags?: BeaconItemTag[];
  previewDescription: string | null;
}

export enum BeaconItemCategory {
  None = "NONE",
  Technique = "TECHNIQUE",
  Tool = "TOOL",
  Technology = "TECHNOLOGY",
  Methodology = "METHODOLOGY"
}

export interface BeaconItemTag {
  it: string;
  name: string;
}

export interface PersonEducation {
  id: number;
  personId: string;
  type: PersonEducationType;
  institution: string;
  name: string;
  description: string | null;
  linkUri: string | null;
  dateAchieved: string;
  includeDateAchievedToProfile: boolean;
}

export enum PersonEducationType {
  Unknown = "UNKNOWN",
  Degree = "DEGREE",
  Diploma = "DIPLOMA",
  Certification = "CERTIFICATION",
  Course = "COURSE"
}

export interface PersonPriorExperience {
  id: number;
  personId: string;
  companyName: string;
  roleName: string;
  roleDescription: string;
  startDate: string;
  endDate: string;
  skillsUsed: PersonPriorExperienceSkill[];
}

export interface PersonPriorExperienceSkill {
  beaconItem: BeaconItem;
}

export interface ProjectParticipant {
  isExcludedFromProfile: boolean;
  contributedTimeInMinutes: number;
  role: string | null;
  duties: string | null;
  project: Project;
  person: Person;
  firstTimeEntryDate: string | null;
  lastTimeEntryDate: string | null;
  skillsUsed: ProjectParticipantSkill[];
}

export interface ProjectParticipantSkill {
  beaconItem: BeaconItem;
}

export interface Project {
  id: number;
  name: string;
  description: string;
  startDate: string | null;
  dueDate: string | null;
  problem: string | null;
  solution: string | null;
  outcome: string | null;
  status: ProjectStatus;
  isArchived: boolean;
  participants: ProjectParticipant[];
  clients?: Client[];
}

export enum ProjectStatus {
  Unknown = "UNKNOWN",
  Contingent = "CONTINGENT",
  InSetup = "IN_SETUP",
  Active = "ACTIVE",
  ActiveOnWatchList = "ACTIVE_ON_WATCH_LIST",
  OnHold = "ON_HOLD",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED"
}

export const getProjectStatusFriendlyName = (status: ProjectStatus) => {
  switch (status) {
    case ProjectStatus.Contingent:
      return "Contingent";
    case ProjectStatus.InSetup:
      return "In setup";
    case ProjectStatus.Active:
      return "Active";
    case ProjectStatus.ActiveOnWatchList:
      return "Active - watch list";
    case ProjectStatus.OnHold:
      return "On hold";
    case ProjectStatus.Completed:
      return "Completed";
    case ProjectStatus.Cancelled:
      return "Cancelled";
    default:
      return "Unknown";
  }
};

export interface Client {
  id: number;
  name: string;
  logoUri?: string;
  logoUploadToken?: string;
  projects?: Project[];
}

export interface PersonPhoto {
  uri: string;
  size: "xs" | "sm" | "md" | "lg" | "xl";
}

export interface PersonWorkingHours {
  daysOfWeek: string[];
  startTime: string;
  endTime: string;
  timeZone: string;
}

export interface GraphQlConnection<T> {
  __typename?: string;
  edges: {
    cursor: string;
    node: T;
  }[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
    endCursor: string;
  };
  totalCount: number;
}

export interface PersonProfileInput {
  id: string;
  bio: string | null;
  devfactoWorkDescription: string | null;
}

export interface ManagerQuickSearch {
  managers?: ManagerQuickSearchResult[];
}

export interface ManagerQuickSearchResult {
  id: string;
  fullName: string;
  photos: PersonPhoto[];
}
